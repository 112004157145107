import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const toastification = {
  methods: {
    toastMessage(title = '', icon = 'CheckIcon', variant = 'success') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    toastSuccess(title) {
      this.toastMessage(title)
    },
    toastFailure(title) {
      this.toastMessage(title, 'XIcon', 'danger')
    },
  },
}

export const _ = null
