import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
// import { ONBOARDING_STEP } from '@/constants'

export default function useVerticalNavMenu(props) {
  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------

  const isVerticalMenuCollapsed = computed({
    get: () => store.state.verticalMenu.isVerticalMenuCollapsed,
    set: val => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', val)
    },
  })

  const userInfo = computed({
    get: () => store.state.auth.user,
  })

  // ------------------------------------------------
  // collapseTogglerIcon
  // ------------------------------------------------
  let setupGuideStep = JSON.parse(localStorage.getItem('isHasOnboardingStep'))
  const isPartner = computed({
    get: () => store.user?.data?.partnership?.status === 'active',
  })
  const isPartnerViewMode = localStorage.getItem('partnerView')

  const collapseTogglerIcon = computed(() => {
    if (props.isVerticalMenuActive) {
      if (!setupGuideStep || (isPartner && isPartnerViewMode === 'true')) {
        isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value
      }

      return isVerticalMenuCollapsed.value ? 'unpinned' : 'pinned'
    }
    return 'close'
  })

  const isMouseHovered = ref(false)

  const updateMouseHovered = val => {
    isMouseHovered.value = (!setupGuideStep || (isPartner && isPartnerViewMode === 'true')) && val
    // if (!setupGuideStep.value) {
    //   isMouseHovered.value = false
    // } else {
    //   isMouseHovered.value = val
    // }
  }

  const toggleCollapsed = () => {
    setupGuideStep = JSON.parse(localStorage.getItem('isHasOnboardingStep'))
    // if (isVerticalMenuCollapsed.value) {
    isMouseHovered.value = false
    // }

    isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value
    if (!setupGuideStep || (isPartner && isPartnerViewMode === 'true')) {
      isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value
    } else if (setupGuideStep) {
      isVerticalMenuCollapsed.value = true
    }
  }

  watch(userInfo, value => {
    if (value) {
      setupGuideStep = JSON.parse(localStorage.getItem('isHasOnboardingStep'))
      if (value && setupGuideStep && isPartnerViewMode !== 'true') {
        toggleCollapsed()
      }
    }
  },
  {
    deep: true,
    immediate: true,
  })

  return {
    isMouseHovered,
    isVerticalMenuCollapsed,
    collapseTogglerIcon,
    toggleCollapsed,
    updateMouseHovered,
  }
}
